import { Flex, Table, TableContainer, Tbody, Td, Tr } from "@chakra-ui/react";
import { t } from "i18next";
import { getScheduleCopy } from "../../../../utils/Job/getScheduleCopy";
import React from "react";
import { TJob } from "../../../../api/modules/Jobs/types";
import { TStatics } from "../../../../api/modules/Statics/types";
import { translations } from "../../../../i18n/translations";
import { getAddressCopy } from "../../../../utils/Job/getAddressCopy";
import { PersonPopover } from "../../../../components/PersonPopover";

type Props = {
  job?: TJob;
  statics?: TStatics;
};

export const JobDataTable = (props: Props) => {
  const screen = translations.screens.job;
  const { job, statics } = props;

  return (
    <Flex flexDirection="column">
      <TableContainer maxWidth="100%" width="100%">
        <Table size="sm" variant="unstyled" whiteSpace="break-spaces">
          <Tbody fontSize="sm">
            <Tr>
              <Td color="gray.500" width="250px">
                {t<string>(screen.table.jobStatus)}
              </Td>
              <Td>{job?.jobStatus}</Td>
            </Tr>
            {job?.dateStarted ? (
              <Tr>
                <Td color="gray.500">{t<string>(screen.table.dateStarted)}</Td>
                <Td>{job?.dateStarted}</Td>
              </Tr>
            ) : (
              ""
            )}
            {job?.dateCompleted ? (
              <Tr>
                <Td color="gray.500">
                  {t<string>(screen.table.dateCompleted)}
                </Td>
                <Td>{job?.dateCompleted}</Td>
              </Tr>
            ) : (
              ""
            )}
            {job?.dateCancelled ? (
              <Tr>
                <Td color="gray.500">
                  {t<string>(screen.table.dateCancelled)}
                </Td>
                <Td>${job?.dateCancelled}</Td>
              </Tr>
            ) : (
              ""
            )}
            <Tr>
              <Td color="gray.500">{t<string>(screen.table.price)}</Td>
              <Td>${job?.totalAmountPaid}</Td>
            </Tr>
            <Tr>
              <Td color="gray.500">{t<string>(screen.table.postedBy)}</Td>
              <Td position="relative">
                <PersonPopover
                  key={job?.homeowner?.userId}
                  email={job?.homeowner?.email}
                  firstName={job?.homeowner?.firstName || ""}
                  lastName={job?.homeowner?.lastName || ""}
                  phone={job?.homeowner?.phoneNumber}
                />
              </Td>
            </Tr>
            <Tr>
              <Td color="gray.500">{t<string>(screen.table.schedule)}</Td>
              <Td>{job ? getScheduleCopy(job, statics) : "-"}</Td>
            </Tr>
            <Tr>
              <Td color="gray.500">{t<string>(screen.table.jobLocation)}</Td>
              <Td>{job && getAddressCopy(job)}</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
};
