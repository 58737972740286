import React, { useCallback } from "react";
import { PaymentsPresentation } from "./Payments.presentation";
import { useGetStripeLink } from "../../../../api/modules/GetStripeLink";
import { Props } from "./Payments.types";

export const Payments = (props: Props) => {
  const { buttonName, stripeAccountId } = props;
  const { mutateAsync, isLoading } = useGetStripeLink();

  const handleClickCTA = useCallback(async () => {
    const link = await mutateAsync();
    window.location.assign(link);
  }, [mutateAsync]);

  return (
    <PaymentsPresentation
      buttonName={buttonName}
      isLoading={isLoading}
      stripeAccountId={stripeAccountId}
      onClickCTA={handleClickCTA}
    />
  );
};
