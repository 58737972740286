import { Endpoints } from "../../../constants/endpoints";
import client from "../../client";
import { TPostMakeOffer } from "./types";

export const postSendQuoteJob = async (jobQuoteId: number) => {
  const response = await client.post<{ jobQuoteId: number }, TPostMakeOffer>(
    Endpoints.postSendQuoteJob(jobQuoteId),
    { jobQuoteId },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
};
