export type InviteFormType = {
  firstName: string;
  lastName: string;
  email: string;
  roles: (string | number)[];
};

export type MakeOfferFormType = {
  price?: number | null;
  details?: string;
  jobId: number;
  jobQuoteStatusId: number;
  jobTypeName?: string | null;
  jobQuoteId?: number | null;
};

export enum PostTruckSteps {
  TruckForm = "truck-form",
  Result = "result",
}
