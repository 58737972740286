import React from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Icon,
  Text,
  Image,
} from "@chakra-ui/react";
import { t } from "i18next";
import StripeImage from "../../../../assets/stripe.png";

import { PresentationProps } from "./Payments.types";
import { translations } from "../../../../i18n/translations";
import { BiLinkExternal } from "react-icons/bi";

export const PaymentsPresentation = (props: PresentationProps) => {
  const screen = translations.screens.settings.payments;
  const { isLoading, onClickCTA, buttonName, stripeAccountId } = props;
  console.log(stripeAccountId);
  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column">
        <Text color="#101828" fontSize="18px" fontWeight={600}>
          {t<string>(screen.pageTitle)}
        </Text>
        <Box mt={2}>
          <Text color="gray.600" fontSize="16px">
            {t<string>(screen.description1)}
          </Text>
          <Text color="gray.600" fontSize="16px">
            {t<string>(screen.description2)}
          </Text>
        </Box>
      </Flex>
      <Divider mt="20px" />
      {!stripeAccountId && (
        <Flex my={4}>
          <Button
            colorScheme="nosPurple"
            fontSize="18px !important"
            fontWeight="500"
            isDisabled={isLoading}
            isLoading={isLoading}
            minWidth="272px"
            rightIcon={<Icon as={BiLinkExternal} />}
            size="lg"
            onClick={onClickCTA}
          >
            {t<string>(!buttonName ? screen.cta : screen.ctaConnect)}
          </Button>
        </Flex>
      )}
      {stripeAccountId && (
        <Flex my={4}>
          <Grid gap={20} templateColumns="repeat(2, 1fr)">
            <GridItem pb={4} pt={4} w="100%">
              <Text color="gray.700" fontWeight={600}>
                {t<string>(screen.stripeLabel)}
              </Text>
            </GridItem>
            <GridItem p={4} w="100%">
              <Flex alignItems="center" gap={2}>
                <Image src={StripeImage} width="40px" />
                <Text>{stripeAccountId}</Text>
              </Flex>
            </GridItem>
          </Grid>
        </Flex>
      )}
    </Flex>
  );
};
