import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AssignJobSteps } from "./AssignJob.types";
import { ModalsContext } from "../../contexts/ModalsContext";
import { AssignJobPresentation } from "./AssignJob.presentation";
import { useStatics } from "../../api/modules/Statics";
import { useJob } from "../../api/modules/Job";
import { useTeam } from "../../api/modules/Team";
import { useAssignJob } from "../../api/modules/AssignJob";
import { useAcceptJob } from "../../api/modules/AcceptJob/useAcceptJob";
import { MakeOfferModal } from "../Jobs/AvailableJobs/components/MakeOfferModal";
import { MakeOfferFormType } from "../Jobs/AvailableJobs/components/MakeOfferModal/MakeOfferModal.types";
import { useJobQuote } from "../../api/modules/GetJobQuote/useJobQuote";

type Props = {
  jobId?: number;
  isReassignMode?: boolean;
  preselectedUserId?: number;
  makeOfferData?: MakeOfferFormType | undefined;
};

export const AssignJob = (props: Props) => {
  const { isReassignMode, preselectedUserId, makeOfferData } = props;
  const { assignJob, makeOfferModal } = useContext(ModalsContext);
  const initialStep = isReassignMode
    ? AssignJobSteps.UserSelection
    : AssignJobSteps.Details;
  const [step, setStep] = useState<AssignJobSteps>(initialStep);
  const { mutateAsync: handleRequestAssignJob } = useAssignJob();
  const { mutateAsync: handleRequestAcceptJob } = useAcceptJob();
  const [currentUser, setCurrentUser] = useState<number | undefined>(
    preselectedUserId
  );
  useEffect(() => {
    setCurrentUser(preselectedUserId);
  }, [preselectedUserId, setCurrentUser]);

  const { data: staticsData } = useStatics();
  const { data: jobData, isLoading } = useJob(props.jobId);
  const { data: teamData } = useTeam();

  const handleCloseModal = useCallback(() => {
    assignJob.onClose();
    setStep(initialStep);
  }, [assignJob, initialStep]);

  const handleSetCurrentUser = useCallback(
    (userId: number) => () => {
      setCurrentUser(userId);
    },
    [setCurrentUser]
  );

  const handleAssignJob = useCallback(() => {
    if (!props.jobId || !currentUser) return;
    handleRequestAssignJob(
      {
        jobId: props.jobId,
        contractorId: currentUser,
      },
      {
        onSuccess: () => {
          setStep(AssignJobSteps.Result);
        },
      }
    );
  }, [handleRequestAssignJob, currentUser, setStep, props.jobId]);

  const handleAcceptJob = useCallback(() => {
    if (!props.jobId) return;
    if (jobData?.totalAmountPaid === 0) {
      assignJob.onClose();
      makeOfferModal.onOpen();
    } else {
      handleRequestAcceptJob(
        { jobId: props.jobId },
        {
          onSuccess: () => {
            setStep(AssignJobSteps.UserSelection);
          },
        }
      );
    }
  }, [
    props.jobId,
    jobData?.totalAmountPaid,
    assignJob,
    makeOfferModal,
    handleRequestAcceptJob,
  ]);

  const currentTeamMemberData = useMemo(() => {
    return (teamData?.users || []).find((item) => item.userId === currentUser);
  }, [currentUser, teamData]);

  const [makeOffer, setMakeOffer] = useState<MakeOfferFormType | undefined>(
    undefined
  );
  const { data: jobQuote } = useJobQuote(props?.jobId);

  useEffect(() => {
    if (jobQuote && makeOfferData) {
      setMakeOffer({
        jobId: makeOfferData.jobId ?? 0,
        price: jobQuote.jobItems?.[0]?.price ?? 0,
        details: jobQuote.jobItems?.[0]?.itemDescription ?? "",
        jobQuoteStatusId: 2,
        jobQuoteId: jobQuote.jobQuoteId,
      });
    } else if (jobQuote == null) {
      setMakeOffer({
        jobId: makeOfferData?.jobId ?? 0,
        price: null,
        details: "",
        jobQuoteStatusId: 2,
      });
    }
  }, [jobQuote, makeOfferData]);

  return (
    <>
      <AssignJobPresentation
        currentTeamMemberData={currentTeamMemberData}
        currentUser={currentUser}
        isLoadingJob={isLoading}
        isOpen={assignJob.isOpen}
        isReassignMode={isReassignMode}
        job={jobData}
        makeOfferData={makeOffer}
        statics={staticsData}
        step={step}
        team={teamData}
        onAccept={handleAcceptJob}
        onAssignJob={handleAssignJob}
        onClose={handleCloseModal}
        onSetCurrentUser={handleSetCurrentUser}
      />
      <MakeOfferModal
        currentMakeOffer={makeOffer}
        makeOfferData={makeOfferData}
      />
    </>
  );
};
