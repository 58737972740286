import { useMutation } from "react-query";
import { useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { postSendQuoteJob } from "./postSendQuoteJob";
import { TData } from "./types";
import { translations } from "../../../i18n/translations";

export const useSendQuoteJob = () => {
  const toast = useToast();
  return useMutation<TData, unknown, number>(
    (jobQuoteId) => postSendQuoteJob(jobQuoteId),
    {
      onError: () => {
        toast({
          title: "Error",
          description: t<string>(translations.errors.send_job_quote_error),
          status: "error",
          position: "top-right",
        });
      },
    }
  );
};
