import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import { translations } from "../../../../../i18n/translations";
import React from "react";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { MakeOfferFormType, PostTruckSteps } from "./MakeOfferModal.types";
import { UseFormRegister } from "react-hook-form";
import { MakeOfferForm } from "./MakeOfferForm/MakeOfferForm";

type Props = {
  isOpen: boolean;
  isLoadingInviteRequest: boolean;
  onClose: () => void;
  onInvite: (data: MakeOfferFormType) => void;
  step: PostTruckSteps;
  inviteErrors: FieldErrors<MakeOfferFormType>;
  inviteRegister: UseFormRegister<MakeOfferFormType>;
  initialValues?: Partial<MakeOfferFormType>;
};

export const MakeOfferModalPresentation = (props: Props) => {
  const {
    isOpen,
    isLoadingInviteRequest,
    initialValues,
    inviteRegister,
    inviteErrors,
    step,
    onClose,
    onInvite,
  } = props;
  const screen = translations.screens.makeOfferModal;
  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        size="sm"
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader mb={0} mt={3} pb={-10} px={6} textAlign="center">
            {step !== PostTruckSteps.Result && (
              <Text fontSize="lg">
                {t<string>(
                  screen[
                    initialValues?.price === null ? "heading" : "updateHeading"
                  ]
                )}
              </Text>
            )}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={5} px={6}>
            <>
              {step === PostTruckSteps.TruckForm && (
                <>
                  <Box mb={5} textAlign="center">
                    <Text fontSize="sm">{t<string>(screen.subHeading)}</Text>
                  </Box>
                  <MakeOfferForm
                    errors={inviteErrors}
                    initialValues={initialValues}
                    isLoading={isLoadingInviteRequest}
                    register={inviteRegister}
                    onClose={onClose}
                    onSubmit={onInvite}
                  />
                </>
              )}
            </>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
