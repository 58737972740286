import React from "react";
import Layout from "../../../components/Layout";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  Portal,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { AiOutlineTable as TableIcon } from "react-icons/ai";
import { t } from "i18next";
import { Routing } from "../../../constants/routing";
import { translations } from "../../../i18n/translations";
import { Modes, PresentationProps } from "./AvailableJobs.types";
import { Link as RouterLink } from "react-router-dom";
import { getScheduleCopy } from "../../../utils/Job/getScheduleCopy";
import { getTagColor } from "../../../utils/Job/getTagColor";
import { Pagination } from "../../../components/Pagination";
import { PersonName } from "../../../components/PersonName";
import { PropertyIcon } from "../Common/PropertyIcon";
import { allJobTypesOption, DISTANCE_OPTIONS } from "./constants";
import FullPageContent from "../../../components/FullPageContent";
import { RiMapPin2Line as PinIcon } from "react-icons/ri";
import { Map } from "./components/Map";
import { getAvailableJobAddressCopy } from "../../../utils/Job/getAvailableJobAddressCopy";
import { getTagColorByStatus } from "../../../utils/Job/getTagColorByStatus";
import { getServiceTypeCopy } from "../../../utils/Job/getServiceTypeCopy";

export const AvailableJobsPresentation = (props: PresentationProps) => {
  const screen = translations.screens.availableJobs;
  const {
    pageTitleRef,
    breadcrumbsRef,
    distance,
    jobTypeId,
    mapCenterCoords,
    isLoading,
    isLoadingProfile,
    jobsData,
    statics,
    pagination,
    zip,
    mode,
    onChangeZip,
    onChangeJobType,
    onChangeDistance,
    onClickJob,
    onChangeMode,
  } = props;

  const {
    currentPage,
    itemsPerPage,
    totalPages,
    isNextDisabled,
    isPrevDisabled,
    onChangeItemsPerPage,
    onClickPrev,
    onClickNext,
  } = pagination;

  const { jobs } = jobsData || {};

  return (
    <Layout>
      <Portal containerRef={pageTitleRef}>{t<string>(screen.pageTitle)}</Portal>
      <Portal containerRef={breadcrumbsRef}>
        <Breadcrumb fontWeight={500}>
          <BreadcrumbItem color="gray.400">
            <BreadcrumbLink as={RouterLink} to={Routing.Root}>
              {t<string>(screen.breadcrumbs.root)}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <div>{t<string>(screen.breadcrumbs.page)}</div>
          </BreadcrumbItem>
        </Breadcrumb>
      </Portal>
      <FullPageContent>
        <Flex justifyContent="space-between">
          <Flex alignItems="center">
            <Text fontSize="lg" fontWeight={700}>
              {t<string>(screen.pageTitle)}
            </Text>
            {jobsData?.totalJobsCount && (
              <Tag borderRadius="full" colorScheme="blue" ml={2} size="md">
                <Text color="blue.500" fontSize="13px" fontWeight={700}>
                  {jobsData?.totalJobsCount}
                </Text>
              </Tag>
            )}
          </Flex>

          {mode === Modes.Map ? (
            <Flex
              alignItems="center"
              cursor="pointer"
              fontSize={14}
              fontWeight={600}
              onClick={onChangeMode(Modes.Table)}
            >
              <Icon as={TableIcon} mr="2" />
              <Text>{t<string>(screen.tableView)}</Text>
            </Flex>
          ) : null}
          {mode === Modes.Table ? (
            <Flex
              alignItems="center"
              cursor="pointer"
              fontSize={14}
              fontWeight={600}
              onClick={onChangeMode(Modes.Map)}
            >
              <Icon as={PinIcon} mr="2" />
              <Text>{t<string>(screen.mapView)}</Text>
            </Flex>
          ) : null}
        </Flex>
        <br />
        <Flex
          flexDirection={["column", "column", "column", "row"]}
          gap={["20px", "20px", "20px", "40px"]}
        >
          <Flex flexBasis={["100%", "100%", "100%", "45%"]}>
            <Select
              maxWidth={["100%", "100%", "100%", "100%"]}
              value={jobTypeId}
              onChange={onChangeJobType}
            >
              {[
                allJobTypesOption,
                ...(statics?.contractorServiceOfferingOptions || []),
              ].map(({ id, name }) => (
                <option key={`${id}-${name}`} value={id}>
                  {name}
                </option>
              ))}
            </Select>
          </Flex>
          <Flex flexBasis={["100%", "100%", "100%", "35%"]}>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <Icon as={PinIcon} color="gray.300" />
              </InputLeftElement>
              <Input
                defaultValue={zip}
                placeholder={t<string>(screen.zip)}
                onChange={onChangeZip}
              />
            </InputGroup>
          </Flex>
          <Flex alignItems="center" flexBasis={["100%", "100%", "100%", "20%"]}>
            <InputGroup justifyContent="flex-end">
              <InputLeftAddon>{t<string>(screen.within)}</InputLeftAddon>
              <Select
                borderRadius=" 0 5px 5px 0"
                maxWidth={["100%", "100%", "100%", "300px"]}
                value={distance}
                onChange={onChangeDistance}
              >
                {DISTANCE_OPTIONS.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
            </InputGroup>
          </Flex>
        </Flex>
        <br />
        {mode === Modes.Table ? (
          <>
            <Flex flex={1} flexDirection="column">
              {isLoading && (
                <Flex justifyContent="center" mt={4}>
                  <Spinner />
                </Flex>
              )}
              <br />
              {!isLoading && (
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>{t<string>(screen.table.property)}</Th>
                        <Th>{t<string>(screen.table.type)}</Th>
                        <Th>{t<string>(screen.table.schedule)}</Th>
                        <Th>{t<string>(screen.table.contact)}</Th>
                        <Th>{t<string>(screen.table.price)}</Th>
                        <Th>{t<string>(screen.table.status)}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {(jobs || []).map((job) => (
                        <Tr
                          key={job.jobId}
                          _hover={{
                            backgroundColor: "gray.50",
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={onClickJob(job.jobId)}
                        >
                          <Td fontWeight={500}>
                            <Flex alignItems="center" width="250px">
                              <PropertyIcon propertyUse={job.propertyUse} />
                              <Flex ml={2} whiteSpace="break-spaces">
                                {getAvailableJobAddressCopy(job)}
                              </Flex>
                            </Flex>
                          </Td>
                          <Td>
                            {job.serviceTypes.length > 0 && (
                              <Flex flexWrap="wrap" gap={1}>
                                {job.serviceTypes
                                  .sort((a, b) => a.id - b.id)
                                  .slice(0, 2)
                                  .map((serviceType) => (
                                    <Tag
                                      key={serviceType.id}
                                      colorScheme={getTagColor(serviceType.id)}
                                    >
                                      <Text size="12px">
                                        {
                                          getServiceTypeCopy(
                                            serviceType.id,
                                            statics
                                          ).serviceType
                                        }
                                      </Text>
                                    </Tag>
                                  ))}
                                {job.serviceTypes.length > 2 && (
                                  <Tag colorScheme="gray">
                                    <Text size="12px">
                                      +{job.serviceTypes.length - 2}
                                    </Text>
                                  </Tag>
                                )}
                              </Flex>
                            )}
                          </Td>
                          <Td>{getScheduleCopy(job, statics)}</Td>
                          <Td>
                            {(job.propertyContacts || []).length
                              ? (job.propertyContacts || []).map((contact) => (
                                  <PersonName
                                    key={contact.emailAddress}
                                    email={contact.emailAddress}
                                    firstName={contact.firstName}
                                    lastName={contact.lastName[0] || ""}
                                  />
                                ))
                              : "-"}
                          </Td>
                          <Td>${job.totalAmountPaid}</Td>
                          <Td>
                            <Tag
                              borderRadius="sm"
                              colorScheme={getTagColorByStatus(job.jobStatus)}
                              fontWeight={600}
                              variant="solid"
                            >
                              {job.jobStatus.toUpperCase()}
                            </Tag>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              )}
              {!isLoading && !(jobs || []).length && (
                <Flex justifyContent="center" mt={4}>
                  <Text fontWeight={500}>{t<string>(screen.noJobs)}</Text>
                </Flex>
              )}
            </Flex>
            <Pagination
              currentPage={currentPage}
              isNextDisabled={isNextDisabled}
              isPrevDisabled={isPrevDisabled}
              itemsPerPage={itemsPerPage}
              mt={4}
              totalPages={totalPages}
              onChangeItemsPerPage={onChangeItemsPerPage}
              onClickNext={onClickNext}
              onClickPrev={onClickPrev}
            />
          </>
        ) : (
          <Map
            apiKey={statics?.googleApiKey || ""}
            isLoadingData={isLoading}
            isLoadingProfile={isLoadingProfile}
            jobs={jobs}
            params={mapCenterCoords}
            onClickJob={onClickJob}
          />
        )}
      </FullPageContent>
    </Layout>
  );
};
