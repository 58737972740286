import { MakeOfferModalPresentation } from "./MakeOfferModal.presentation";
import React, { useContext, useState } from "react";
import { ModalsContext } from "../../../../../contexts/ModalsContext";
import { useForm } from "react-hook-form";
import { PostTruckSteps, MakeOfferFormType } from "./MakeOfferModal.types";
import { useQuoteJob } from "../../../../../api/modules/UpdateQuoteJob";
import { useToast } from "@chakra-ui/react";
import { translations } from "../../../../../i18n/translations";
import { t } from "i18next";
import { Queries } from "../../../../../constants/queries";
import { useQueryClient } from "react-query";
import { useSendQuoteJob } from "../../../../../api/modules/SendJobQuote";

type Props = {
  makeOfferData?: Partial<MakeOfferFormType> | undefined;
  currentMakeOffer?: Partial<MakeOfferFormType> | undefined;
};

export const MakeOfferModal = (props: Props) => {
  const { makeOfferData, currentMakeOffer } = props;
  const toast = useToast();
  const screen = translations.screens.makeOfferModal;
  const { makeOfferModal } = useContext(ModalsContext);
  const [step] = useState<PostTruckSteps>(PostTruckSteps.TruckForm);
  const { mutateAsync: mutateAsyncInvite, isLoading: isLoadingInviteRequest } =
    useQuoteJob();
  const {
    handleSubmit: handleSubmitInvite,
    register: registerInvite,
    formState: { errors: errorsInvite },
  } = useForm<MakeOfferFormType>();

  const { mutateAsync: sendQuoteJobAsync } = useSendQuoteJob();
  const queryClient = useQueryClient();

  const onSubmitInvite = async (data: MakeOfferFormType) => {
    await handleSubmitInvite(async () => {
      await mutateAsyncInvite(
        {
          jobId: data?.jobId ?? null,
          JobQuoteId: data?.jobQuoteId ?? null,
          jobQuoteStatusId: 2, //2=Sent
          jobItems: [
            {
              itemDescription: data.details ?? null,
              price: data.price ?? null,
              itemName: makeOfferData?.jobTypeName ?? null,
              quantity: 1,
            },
          ],
        },
        {
          onSuccess: (res) => {
            toast({
              title: t<string>(screen.success),
              description: t<string>(
                currentMakeOffer?.price === null
                  ? screen.successMassage
                  : screen.updateSuccessMassage
              ),
              position: "top-right",
              status: "success",
              isClosable: true,
              variant: "subtle",
              containerStyle: { color: "#067647" },
            });
            queryClient.invalidateQueries(Queries.JobQuote(data.jobId));
            makeOfferModal.onClose();
            if (currentMakeOffer?.price === null) {
              sendQuoteJobAsync(res?.jobQuoteId ?? 0);
            }
          },
        }
      );
    })();
  };

  const onClose = async () => {
    makeOfferModal.onClose();
  };

  return (
    <MakeOfferModalPresentation
      initialValues={currentMakeOffer}
      inviteErrors={errorsInvite}
      inviteRegister={registerInvite}
      isLoadingInviteRequest={isLoadingInviteRequest}
      isOpen={makeOfferModal.isOpen}
      step={step}
      onClose={onClose}
      onInvite={onSubmitInvite}
    />
  );
};
