import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Routing } from "../../constants/routing";
import { useInitializeGoogleAuth } from "../../hooks/useInitializeGoogleAuth";
import { useInitializeAppleAuth } from "../../hooks/useInitializeAppleAuth";
import { CredentialResponse } from "google-one-tap";
import { SignupPresentation } from "./Signup.presentation";
import { SignInFormType } from "./Signup.types";
import { useRegisterUser } from "../../api/modules/RegisterUser";
import { useAppleSignUp } from "../../api/modules/AppleSignUp";
import { useGoogleSignUp } from "../../api/modules/GoogleSignUp";
import { getAccessToken } from "../../utils/getAccessToken";

export const Signup = () => {
  const { mutateAsync: handleMutateEmailSignIn } = useRegisterUser();
  const { mutateAsync: handleMutateGoogleSignIn } = useGoogleSignUp();
  const { mutateAsync: handleMutateAppleSignIn } = useAppleSignUp();
  const navigate = useNavigate();
  // const location = useLocation();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting, isValid },
  } = useForm<SignInFormType>();

  // const getPaywallPath = useCallback(() => {
  //   const queryParams = new URLSearchParams(location.search);
  //   const planPackageId = queryParams.get("planPackageId");

  //   return planPackageId
  //     ? `${Routing.PayWall}?planPackageId=${planPackageId}`
  //     : Routing.PayWall;
  // }, [location.search]);

  const getProfilePath = () => {
    return `${Routing.Profile}?startup-modal=${true}`;
  };

  useEffect(() => {
    const isAuthenticated = Boolean(getAccessToken());
    if (isAuthenticated) {
      navigate(Routing.Root);
    }
  }, [navigate]);

  const handleGoogleSignIn = useCallback(
    (response: CredentialResponse) => {
      if (!response.credential) return;
      handleMutateGoogleSignIn(
        { token: response.credential },
        {
          onSuccess: () => {
            navigate(getProfilePath());
            // navigate(getPaywallPath());
          },
        }
      );
    },
    [handleMutateGoogleSignIn, navigate]
  );

  useInitializeGoogleAuth({
    callback: handleGoogleSignIn,
    context: "signup",
  });

  useInitializeAppleAuth();

  const onSubmit = handleSubmit(async (data) => {
    await handleMutateEmailSignIn(
      {
        email: data.email,
        firstName: data.firstName,
        password: data.password,
        referralCode: data.referralCode,
      },
      {
        onSuccess: () => {
          navigate(getProfilePath());
          // navigate(getPaywallPath());
        },
        onError: () => {
          reset(undefined, { keepValues: true });
        },
      }
    );
  });

  const handleAppleSignIn = useCallback(
    async (referralCode: string | null) => {
      const response = await AppleID.auth.signIn();
      if (!response?.authorization?.id_token) return;
      handleMutateAppleSignIn(
        {
          token: response.authorization.id_token,
          referralCode: referralCode,
        },
        {
          onSuccess: () => {
            navigate(getProfilePath());
            // navigate(getPaywallPath());
          },
        }
      );
    },
    [navigate, handleMutateAppleSignIn]
  );

  return (
    <SignupPresentation
      errors={errors}
      isSubmitting={isSubmitting}
      isValid={isValid}
      register={register}
      onAppleSignIn={handleAppleSignIn}
      onSubmit={onSubmit}
    />
  );
};
